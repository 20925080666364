<template>
  <v-container fluid>
    <v-row class="ma-3" wrap>
      <!-- nome -->
      <v-col cols="12" sm="6" md="6">
        <v-text-field
          label="Nome do Setor"
          ref="nome"
          placeholder="Ex.: Administrativo"
          required
          outlined
          dense
          v-model="setor.nome"
          data-vv-as="nome"
          persistent-hint
          :hint="errors.first('adm_setores.nome')"
          :error="errors.collect('adm_setores.nome').length ? true : false"
          :hide-details="
            errors.collect('adm_setores.nome').length ? false : true
          "
          v-validate="'required'"
          data-vv-scope="adm_setores"
          data-vv-validate-on="change"
          id="nome"
          name="nome"
        ></v-text-field>
      </v-col>
      <!-- Filial -->
      <v-col cols="12" sm="6" md="6">
        <v-select
          label="Filial"
          ref="filial"
          placeholder="Selecione uma filial da empresa"
          :items="filiais"
          item-text="nome"
          item-value="id"
          dense
          outlined
          v-model="setor.id_filial"
          :loading="loadings.loadingFilial"
          clearable
          hide-details
        ></v-select>
      </v-col>
      <!-- TipoSetores -->
      <v-col cols="12" sm="6" md="6">
        <v-select
          label="Tipo"
          ref="tipo"
          placeholder="Selecione um tipo"
          :items="tiposSetores"
          item-text="descricao"
          item-value="id"
          dense
          outlined
          v-model="setor.id_tipo"
          :loading="loadings.loadingTipoSetor"
          clearable
          hide-details
        ></v-select>
      </v-col>
      <!-- Cliente -->
      <v-col cols="12" sm="6" md="6">
        <v-select
          label="cliente"
          ref="cliente"
          placeholder="Selecione um cliente"
          :items="clientes"
          item-text="nome"
          item-value="id"
          dense
          outlined
          v-model="setor.id_cliente"
          hide-details
          :loading="loadings.loadingCliente"
          clearable
        ></v-select>
      </v-col>
      <!-- responsavel -->
      <v-col cols="12" sm="12" md="6" lg="6">
        <v-select
          label="Responsável"
          :items="responsaveis"
          item-text="nome"
          item-value="id"
          placeholder="Selecione um usuário"
          outlined
          dense
          required
          v-model="setor.id_responsavel"
          data-vv-as="responsável"
          persistent-hint
          :hint="errors.first('adm_setores.responsavel')"
          :error="
            errors.collect('adm_setores.responsavel').length ? true : false
          "
          :hide-details="
            errors.collect('adm_setores.responsavel').length ? false : true
          "
          v-validate="'required'"
          data-vv-scope="adm_setores"
          data-vv-validate-on="change"
          id="responsavel"
          name="responsavel"
          :loading="loadings.loadingResponsavel"
        ></v-select>
      </v-col>
      <!-- descricao -->
      <v-col cols="12" sm="12" md="12">
        <v-textarea
          label="Descrição"
          ref="descricao"
          placeholder="Ex.: Uma parte essencial da empresa."
          outlined
          dense
          v-model="setor.descricao"
          data-vv-as="descrição"
          persistent-hint
          :hint="errors.first('adm_setores.descricao')"
          :error="errors.collect('adm_setores.descricao').length ? true : false"
          :hide-details="
            errors.collect('adm_setores.descricao').length ? false : true
          "
          v-validate="'required'"
          data-vv-scope="adm_setores"
          data-vv-validate-on="change|blur|input"
          id="descricao"
          name="descricao"
        ></v-textarea>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
export default {
  name: "SetoresForm",
  inject: ["$validator"],
  async created() {
    const dataFilial = {
      columns: ["id", "nome"],
      validate: [
        ["status", "A"],
        ["id_empresa", this.empresa.informacoes.id],
      ],
    };
    const dataTiposSetores = {
      columns: ["id", "descricao"],
      validate: null,
    };
    const dataClientes = {
      columns: ["id", "nome"],
      validate: [
        ["status", "A"],
        ["id_empresa", this.empresa.informacoes.id],
      ],
    };
    const dataResponsaveis = {
      columns: ["id", "nome"],
      validate: [
        ["status", "A"],
        ["gestor", 1],
        ["id_empresa", this.empresa.informacoes.id],
      ],
    };

    await this.$store.dispatch("filiais/findByColumns", dataFilial);
    this.loadings.loadingFilial = false;

    await this.$store.dispatch("tiposSetores/findByColumns", dataTiposSetores);
    this.loadings.loadingTipoSetor = false;

    await this.$store.dispatch("clientes/getAllDatabyColumns", dataClientes);
    this.loadings.loadingCliente = false;

    await this.$store.dispatch("usuarios/getDataByColumn", dataResponsaveis);
    this.loadings.loadingResponsavel = false;
  },
  data() {
    return {
      loadings: {
        loadingFilial: true,
        loadingResponsavel: true,
        loadingCliente: true,
        loadingTipoSetor: true,
      },
    };
  },
  computed: {
    empresa: {
      get() {
        return this.$store.getters["empresas/getEmpresaSelecionada"];
      },
    },
    setor: {
      get() {
        return this.$store.getters["setores/getSetor"];
      },
      set(value) {
        this.$store.dispatch("setores/setSetor", value);
      },
    },
    filiais: {
      get() {
        return this.$store.getters["filiais/getFiliais"];
      },
    },
    responsaveis: {
      get() {
        return this.$store.getters["usuarios/getResponsaveis"];
      },
    },
    tiposSetores: {
      get() {
        return this.$store.getters["tiposSetores/getTiposSetores"];
      },
    },
    clientes: {
      get() {
        return this.$store.getters["clientes/getClientes"];
      },
    },
  },
};
</script>

<style></style>
