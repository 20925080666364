<template>
  <section name="setores">
    <v-row>
      <v-col cols="12">
        <Crud
          title="Gestão de Setores"
          buttonAddItemText="Novo Setor"
          searchLabelText="Pesquisar na lista"
          :searchTextField.sync="searchTextField"
          :formTitle="nameForm"
          :callBackModalCancelar="fechar"
          :dialog.sync="dialog"
          :callBackModalSalvar="salvar"
          :dialogDelete.sync="dialogDelete"
        >
          <template slot="data-content">
            <v-row class="mt-5">
              <!-- FILTRO CAMPOS -->
            </v-row>
          </template>
          <template slot="data-table">
            <v-data-table
              :headers="
                headers.filter((header) => {
                  return header.value != 'editar' && header.value != 'acao';
                })
              "
              :items="setores"
              class="elevation-5"
              :loading="loadingTable"
            >
              <!-- ACTIONS -->
              <template v-slot:[`item.actions`]="{ item }">
                <v-menu bottom left>
                  <template v-slot:activator="{ on, attrs }">
                    <v-btn icon v-bind="attrs" v-on="on">
                      <v-icon>mdi-dots-vertical</v-icon>
                    </v-btn>
                  </template>

                  <v-list>
                    <!-- Visualizar -->
                    <v-list-item>
                      <v-list-item-title>
                        <v-tooltip bottom>
                          <template v-slot:activator="{ on, attrs }">
                            <v-btn icon v-bind="attrs" v-on="on">
                              <v-icon
                                class="actions"
                                @click="toggleModalLateral(item)"
                              >
                                mdi-eye
                              </v-icon>
                            </v-btn>
                          </template>
                          <span>Visualizar</span>
                        </v-tooltip>
                      </v-list-item-title>
                    </v-list-item>

                    <!-- Editar -->
                    <v-list-item>
                      <v-list-item-title>
                        <v-tooltip bottom>
                          <template v-slot:activator="{ on, attrs }">
                            <v-btn icon v-bind="attrs" v-on="on">
                              <v-icon class="actions" @click="editar(item)">
                                mdi-pencil
                              </v-icon>
                            </v-btn>
                          </template>
                          <span>Editar</span>
                        </v-tooltip>
                      </v-list-item-title>
                    </v-list-item>

                    <!-- Deletar -->
                    <v-list-item>
                      <v-list-item-title>
                        <v-tooltip bottom>
                          <template v-slot:activator="{ on, attrs }">
                            <v-btn icon v-bind="attrs" v-on="on">
                              <v-icon
                                class="actions"
                                @click="remover(item, true)"
                              >
                                mdi-delete
                              </v-icon>
                            </v-btn>
                          </template>
                          <span>Deletar</span>
                        </v-tooltip>
                      </v-list-item-title>
                    </v-list-item>
                  </v-list>
                </v-menu>
              </template>
              <template v-slot:[`item.status`]="{ item }">
                <v-switch
                  class="mt-0 pt-0"
                  v-model="item.status"
                  @click="mudarStatus(item)"
                  :label="item.status == 'A' ? 'Ativo' : 'Inativo'"
                  true-value="A"
                  false-value="I"
                  color="success"
                  value="A"
                  hide-details
                ></v-switch>
              </template>
              <template v-slot:[`item.responsavel`]="{ item }">
                <span v-if="item.responsavel && item.responsavel.nome">
                  {{ item.responsavel.nome }}
                </span>
                <v-chip small color="error" dark v-else>
                  <v-icon class="mr-1"> mdi-information-outline </v-icon> Não
                  tem responsável
                </v-chip>
              </template>
              <template v-slot:[`item.filial`]="{ item }">
                <span v-if="item.filial && item.filial.nome">
                  {{ item.filial.nome }}
                </span>
                <v-chip small color="accent" dark v-else>
                  <v-icon class="mr-1"> mdi-information-outline </v-icon> Não
                  tem filial
                </v-chip>
              </template>
            </v-data-table>
          </template>
          <!--------------- MODAL DE DELETE --------------------------------------------- -->
          <template slot="modalDelete/titleModal">
            Excluir o registro?
          </template>
          <template slot="modalDelete/contentModal">
            <p>
              Atenção! Você está prestes a realizar uma ação que não pode ser
              desfeita.
            </p>
            <p>Você realmente deseja excluir esse registro?</p>
          </template>
          <template slot="modalDelete/actionsModal">
            <v-btn depressed @click="dialogDelete = false">Fechar</v-btn>
            <v-btn
              :loading="loadingButtonDelete"
              color="error"
              @click="remover()"
            >
              Excluir
            </v-btn>
            <v-spacer class="d-none d-md-block"></v-spacer>
          </template>
          <!-- ------------- MODAL DE CADASTRO --------------------------------------------- -->
          <template slot="contentModalCrud">
            <SetoresForm />
          </template>
        </Crud>
      </v-col>
    </v-row>

    <ModalLateral
      :show="modalLateral.show"
      :hide="toggleModalLateral"
      :title="modalLateral.title"
      :headers="headerModalLateral"
      :item="modalLateral.item"
    >
      <template v-slot:[`item.status`]="{ item }">
        <v-chip :color="item.status == 'A' ? 'success' : 'secondary'">{{
          item.status == "A" ? "Ativo" : "Inativo"
        }}</v-chip>
      </template>
      <template v-slot:[`item.created_at`]="{ item }">
        <span
          >{{ item.created_at | dateFormatBr() }}
          {{ item.created_at | timeFormatBr() }}h</span
        >
      </template>
      <template v-slot:[`item.updated_at`]="{ item }">
        <span
          >{{ item.updated_at | dateFormatBr() }}
          {{ item.updated_at | timeFormatBr() }}h</span
        >
      </template>
      <template v-slot:[`item.responsavel`]="{ item }">
        <span v-if="item.responsavel && item.responsavel.nome">
          {{ item.responsavel.nome }}
        </span>
        <v-chip small color="error" dark v-else>
          <v-icon class="mr-1"> mdi-information-outline </v-icon> Não tem
          responsável
        </v-chip>
      </template>
      <template v-slot:[`item.cliente`]="{ item }">
        <span v-if="item.cliente && item.cliente.nome">
          {{ item.cliente.nome }}
        </span>
        <v-chip small color="accent" dark v-else>
          <v-icon class="mr-1"> mdi-information-outline </v-icon> Não tem
          cliente
        </v-chip>
      </template>
      <template v-slot:[`item.filial`]="{ item }">
        <span v-if="item.filial && item.filial.nome">
          {{ item.filial.nome }}
        </span>
        <v-chip small color="accent" dark v-else>
          <v-icon class="mr-1"> mdi-information-outline </v-icon> Não tem filial
        </v-chip>
      </template>
      <template v-slot:[`item.tipo_setor`]="{ item }">
        <span v-if="item.tipo_setor && item.tipo_setor.descricao">
          {{ item.tipo_setor.descricao }}
        </span>
        <v-chip small color="accent" dark v-else>
          <v-icon class="mr-1"> mdi-information-outline </v-icon> Não tem tipo
          do setor.
        </v-chip>
      </template>
    </ModalLateral>
  </section>
</template>

<script>
import Crud from "@/components/Crud.vue";
import ModalLateral from "@/components/ModalLateral.vue";
import SetoresForm from "./forms/SetoresForm.vue";

export default {
  name: "Setores",
  inject: ["$validator"],
  components: {
    Crud,
    ModalLateral,
    SetoresForm,
  },
  data() {
    return {
      nameForm: "Novo Setor",
      modalLateral: {
        show: false,
        item: [],
        title: "",
      },
      searchTextField: "",
      dialog: false,
      loadingTable: false,
      headers: [
        { text: "Nome", value: "nome" },
        { text: "Responsável", value: "responsavel" },
        { text: "Filial", value: "filial" },
        { text: "Status", value: "status" },
        { text: "Ações", value: "actions" },
      ],
      headerModalLateral: [
        { text: "Filial", value: "filial" },
        { text: "Responsável", value: "responsavel" },
        { text: "Cliente", value: "cliente" },
        { text: "Tipo do setor", value: "tipo_setor" },
        { text: "Descrição", value: "descricao" },
        { text: "Status", value: "status" },
        { text: "Data de criação", value: "created_at" },
        { text: "Data de atualização", value: "updated_at" },
      ],

      dialogDelete: false,
      loadingButtonDelete: false,
      item: [],
    };
  },

  created() {
    this.loadingTable = true;

    this.$store.dispatch("setores/initialize").finally(() => {
      this.loadingTable = false;
    });
  },

  computed: {
    setores: {
      get() {
        return this.$store.getters["setores/getSetores"];
      },
    },
    setor: {
      get() {
        return this.$store.getters["setores/getSetor"];
      },
      set(value) {
        this.$store.dispatch("setores/setSetor", value);
      },
    },
    empresa: {
      get() {
        return this.$store.getters["empresas/getEmpresaSelecionada"];
      },
    },
  },

  methods: {
    salvar() {
      this.$validator.validate("adm_setores.*").then((result) => {
        if (result) {
          const isEdit = this.setor.id || this.setor.uuid ? 1 : -1;

          this.dialog = false;
          this.loadingTable = true;
          let url = isEdit === -1 ? "/setor" : "/setor/" + this.setor.uuid;
          let method = isEdit === -1 ? "POST" : "PUT";
          let sucesso =
            isEdit === -1
              ? "Setor cadastrado com sucesso."
              : "Setor atualizado com sucesso.";
          let erro =
            isEdit === -1
              ? "Erro ao salvar setor."
              : "Erro ao atualizar setor.";

          let setorItem = this.setor;
          setorItem._method = method;
          setorItem.url = url;

          this.$store
            .dispatch("setores/save", setorItem)
            .then((response) => {
              if (response != "NAO_AUTORIZADO") {
                if (isEdit === -1) {
                  this.setores.push(response);
                } else {
                  const index = this.setores.findIndex(
                    (el) => el.id == setorItem.id
                  );

                  Object.assign(this.setores[index], setorItem);
                }

                this.$store.dispatch("snackbar/ativarSnackbarGlobal", {
                  visibilidade: true,
                  mensagem: sucesso,
                  cor: "green",
                });
              }
            })
            .catch((error) => {
              console.log(error);
              this.$store.dispatch("snackbar/ativarSnackbarGlobal", {
                visibilidade: true,
                mensagem: erro,
                cor: "red",
              });
            })
            .finally(() => {
              this.fechar();
            });
        }
      });
    },
    editar(item) {
      this.loadingTable = true;

      this.$store
        .dispatch("setores/findByUuid", item.uuid)
        .then((response) => {
          this.setor = response;
          this.nameForm = "Editar setor: " + this.setor.nome;
          this.dialog = true;
        })
        .catch(() => {
          this.$store.dispatch("snackbar/ativarSnackbarGlobal", {
            visibilidade: true,
            mensagem:
              "Houve um erro ao buscar todos os dados do setor selecionado.",
            cor: "red",
          });
        })
        .finally(() => {
          this.loadingTable = false;
        });
    },
    fechar() {
      this.dialog = false;
      this.loadingTable = false;
      this.nameForm = "Novo setor";
      this.setor = {
        id_filial: null,
        id_cliente: null,
        id_tipo: null,
        id_responsavel: null,
        nome: "",
        descricao: "",
        status: "",
      };
    },
    remover(item, openModal = false) {
      if (openModal) {
        this.item = Object.assign({}, item);
        this.dialogDelete = true;
      } else {
        this.loadingButtonDelete = true;
        const indexItem = this.setores.findIndex((el) => el.id == this.item.id);

        this.$store
          .dispatch("setores/remove", this.item.uuid)
          .then((response) => {
            if (response) {
              this.setores.splice(indexItem, 1);

              this.$store.dispatch("snackBarModule/openSnackBar", {
                color: "success",
                timeout: 3000,
                text: "Setor removido.",
              });
              this.item = {};
            }
          })
          .catch(() => {
            this.$store.dispatch("snackBarModule/openSnackBar", {
              color: "error",
              timeout: 3000,
              text: "Não foi possível remover o setor.",
            });
          })
          .finally(() => {
            this.loadingButtonDelete = false;
            this.dialogDelete = false;
          });
      }
    },
    mudarStatus(item) {
      this.loadingTable = true;

      let setorItem = {
        status: item.status,
        _method: "PUT",
        url: "/setor/" + item.uuid,
      };

      this.$store
        .dispatch("setores/save", setorItem)
        .then((response) => {
          if (response != "NAO_AUTORIZADO") {
            this.$store.dispatch("snackbar/ativarSnackbarGlobal", {
              visibilidade: true,
              mensagem: "Setor atualizado com sucesso.",
              cor: "green",
            });
          }
        })
        .catch((error) => {
          console.log(error);
          this.$store.dispatch("snackbar/ativarSnackbarGlobal", {
            visibilidade: true,
            mensagem: "Erro ao atualizar setor.",
            cor: "red",
          });
        })
        .finally(() => {
          this.loadingTable = false;
        });
    },
    //Modal Lateral
    toggleModalLateral(item) {
      if (this.modalLateral.show == false) {
        this.loadingTable = true;

        this.$store
          .dispatch("setores/findByUuid", item.uuid)
          .then((response) => {
            this.modalLateral.title = response.nome;
            this.modalLateral.item = response ? response : [];
            this.modalLateral.show = true;
          })
          .catch(() => {
            this.$store.dispatch("snackbar/ativarSnackbarGlobal", {
              visibilidade: true,
              mensagem:
                "Houve um erro ao buscar todos os dados do setor selecionado.",
              cor: "red",
            });
          })
          .finally(() => {
            this.loadingTable = false;
          });
      } else {
        this.modalLateral.show = false;
      }
    },
    //DATATABLE COSTUMIZATION
    getColor(status) {
      if (status == "A" || status == "Ativo") return "green";
      else return "red";
    },
  },
};
</script>

<style scoped></style>
